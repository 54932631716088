<template>
  <el-dialog :title="title" :visible.sync="show" class="choose-selectedPlan-importFail">
    <el-table :data="table.data" style="width: 100%" max-height="500" fit align="center">
      <el-table-column type="index" label="序号" ></el-table-column>
      <el-table-column prop="name" label="姓名" width="80"></el-table-column>
      <el-table-column prop="idCard" label="身份证号码" width="180"></el-table-column>
      <el-table-column prop="oftenLive" label="常居住地" ></el-table-column>
      <el-table-column prop="failReason" label="失败原因" class="failReason"></el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
export default {
  // 导入失败
  name: 'importFail',
  props: {
    title: {
      tyle: String,
    },
  },
  components: {},
  data() {
    return {
      show: false,
      table: {
        data: [
          { name: '张三张三', idCard: 421202199909092362, oftenLive: '江西省南昌市创新一路', failReason: '重复' },
          { name: '张三', idCard: 421202199909092362, oftenLive: '江西省南昌市创新一路', failReason: '行政区划代码不匹配' },
          { name: '张三', idCard: 421202199909092362, oftenLive: '江西省南昌市创新一路', failReason: '行政区划代码不匹配' },
          { name: '张三', idCard: 421202199909092362, oftenLive: '江西省南昌市创新一路', failReason: '重复' },
          { name: '张三', idCard: 421202199909092362, oftenLive: '江西省南昌市创新一路', failReason: '重复' },
        ],
      },
    };
  },
  methods: {
    download() {
      this.$refs.code.click();
    },
    toggle(show) {
      this.show = show;
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
::v-deep .el-table__row {
  .el-table__cell:nth-of-type(5) {
    color: #ec808d;
  }
}
.el-table {
  border: 1px solid rgba(232, 232, 232, 1);
  border-bottom: none;
}
</style>
